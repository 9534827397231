
@import "~bootstrap-icons/font/bootstrap-icons.css";
@import "../node_modules/@angular/material/prebuilt-themes/deeppurple-amber.css";
.nanobar{
  position:fixed;
  height:15px;
  margin-top:30px;
  background: white;
  z-index: 899;
}

.bar{
  background: cyan;
  position:fixed;
  height:15px;
}

.custom-title{
  font-size: 28px;
  color: white;
  text-align: center;
  font-family: Montserrat;
  font-weight: bold;
  line-height: 2.0;
  background-color: #052E66;
}

.invalid{
  color:#c30101;
  font-family:Montserrat;
}

.central {
  padding-top: 20px;
  width: 700px;
  min-height:600px;
  margin: auto;
  display:flex;
  background: #FFFFFF;
  border-radius: 20px;
  }
  
.body-container{
  font-weight: bold;
  font-family: Montserrat;
  font-size: 14px;
  color: black;
  line-height: 1.8;
  padding-left: 50px;
  padding-right: 50px;
}

.navigation-container{
  font-weight: bold;
  font-family: Montserrat;
  font-size: 14px;
  padding-left: 30px;
  padding-right: 30px;
}

@media (max-width: 768px){
  .central {
      max-width: 540px;
  }
}

@media (max-width: 600px){
  .central {
      width: 100%;
  }
  .body-container{
    padding-left: 20px;
    padding-right: 20px;
  }
  
  .navigation-container{
    padding-left: 10px;
    padding-right: 10px;
  }
}

html,body
{
  background-image: url(./assets/images/background-img.jpg);
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden; 
}
a {
    color: #0366d6;
  }
  
  code {
    color: #e01a76;
  }
  
  .btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
  }
  
  a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
  }
  
  .border-top {
    border-top: none !important;
  }
  
  .border-bottom {
    border-bottom: 1px solid #e5e5e5;
  }
  
  .box-shadow {
    box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
  }
  
  button.accept-policy {
    font-size: 1rem;
    line-height: inherit;
  }
  
  /*-------------------------------------------------- */
  html {
    position: relative;
    min-height: 100%;
  }
  
  body {
    /* Margin bottom by footer height */
    margin-bottom: 60px;
    /* min-width: 1170px; */
    color: #141414;    
    font-family: Montserrat;
    font-weight: bold;
    }
  

  header {
    /*background: white;*/
    background: rgba(22, 76, 103, 0.1);
  }

  
  /* info buttons */
  .ui-btn.my-tooltip-btn,
  .ui-btn.my-tooltip-btn:hover,
  .ui-btn.my-tooltip-btn:active {
    background: none;
    border: 0;
  }
  
  /*--------Page container--------------*/
  
  .page-container {
    min-width: 1170px;
    padding: 0 15px 0 15px;
    margin: 0 auto 0 auto;
  }
  
  
  /*--------Flex elements--------------*/
  .flex-wrapper {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 100%;
  }
  
  .no-wrap {
    flex-wrap: nowrap;
  }
  
  .flex-vertical {
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
  }
  
  .flex-center-vertical {
    align-items: center;
  }
  
  

  

  .input-label {
    /* width: 760px; */
    margin: 8px auto 0 0;
    font-size: 14px;
    line-height: 2.2;
  }
  
  .input-field {
    width: 400px;
  }
  
  .input-shadow {
    box-shadow: 0px 0px 6px 6px rgba(0, 0, 0, 0.1);
  }
  
  .input-info {
    width: 360px;
    padding: 3px 0 3px 0;
  }
  
  .input-filler {
    width: calc(100% - 800px);
    min-width: 0;
  }
  
  .indemnity-menu {
    width: 100%
  }
  
  .title-font {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    text-shadow: none;
    text-align:center;
  }
  
  .Montserrat {
    font-family: 'Montserrat', sans-serif;
    font-weight: normal;
    text-shadow: none;
  }
  
  .display {
    visibility: visible !important;
    -webkit-animation: fadeIn 1s;
    animation: fadeIn 1s;
  }
  
  /* ----- Modal CSS ----- */
  .modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  }
  
  /* Modal Content */
  .modal-content {
    background-color: #fefefe;
    font: 16px;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    border-radius: 8px;
    width: 80%;
    max-width: 800px;
  }
  
  .video-modal-button {
    background: #1FD99C;
    border: 2px solid #1FD99C;
    border-radius:0px;
    color: white;
    text-align: center;
    text-decoration: none;
    padding: 10px 25px 10px 25px;
    font-size: 14px;
    cursor: pointer;
    font-weight: bold;
    line-height: normal;
  }
  
  .video-modal-button:hover {
    background-color: white;
    border: 2px solid #1FD99C;
    color: #1FD99C;
  }

  .close {
    background-color: rgba(0, 83, 123, 0.8);
    border: none;
    border-radius: 16px;
    color: white !important;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    text-shadow: none !important;
    display: inline-block;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 400 !important;
    margin: 16px auto;
    cursor: pointer;
    opacity: 1.0 !important;
  }
  
    .close:hover,
    .close:focus {
      text-decoration: none;
      cursor: pointer;
      background-color: rgba(0, 83, 123, 0.8);
      color: white !important;
      opacity: 0.75 !important;
    }
  
  .modal-text {
    margin-bottom: 16px;
    margin-left: 24px;
    font-family: roboto;
  }
  
  .modal-title {
    text-align: center;
    margin: 16px 0;
  }
  
  ul.dotted {
    list-style-type: disc;
  }
  
  /*-----Custom Radio Buttons-----*/
  
  /* The radio-container */
  .radio-container {
    display:inline-block; 
    position: relative;
    text-align: right;
    cursor: pointer;
    font-size: 20px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
    /* Hide the browser's default radio button */
    .radio-container input {
      position: relative;
      opacity: 0;
      cursor: pointer;
    }
  
  /* Create a custom radio button */
  .checkmark {
    position: relative;
    top: 6px;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: white;
    border: 1px solid black;
    border-radius: 25%;
    box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.1);
  }
  
  /* On mouse-over, add a grey background color */
  .radio-container:hover input ~ .checkmark {
    background-color: #ccc;
  }
  
  /* When the radio button is checked, add a blue background */
  .radio-container input:checked ~ .checkmark {
    background-color: white;
  }
  
  /* Create the indicator (the dot/circle - hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the indicator (dot/circle) when checked */
  .radio-container input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the indicator (dot/circle) */
  .radio-container .checkmark:after {
    top: 5px;
    left: 5px;
    width: 13px;
    height: 13px;
    border-radius: 25%;
    background: black;
  }
  
  /*----- Checkbox Styling -----*/
  
  .checkbox {
    height: 25px;
    width: 25px;
    margin: 0 20px 0 10px;
    border: 2px solid rgba(0, 83, 123, 0.8);
    border-radius: 25%;
  }
  
  /*----- Summary Styling -----*/
  
  .Delete-Button {
    background-color: rgba(200, 0, 0, 0.8);
    border: hidden;
    border-radius: 8px;
    color: white;
    font-size: 20px;
    line-height: 16px;
    padding: 8px 16px;
    margin: 0 231px;
  }
  
    .Delete-Button:hover {
      background-color: rgba(200, 0, 0, 0.6);
      cursor: pointer;
    }
  
  .Green-Button {
    background-color: rgba(50, 177, 116, 1.0);
    border: hidden;
    border-radius: 16px;
    height: 48px;
    padding: 8px 32px 8px 32px;
    margin: 5px auto;
  }
  
    .Green-Button:hover {
      background-color: rgba(50, 177, 116, 0.8);
    }

  
  
  /*---------- Drop Down Menu ----------*/
  .dropbtn {
    cursor: pointer;
  }
  
  /* The container <div> - needed to position the dropdown content */
  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  /* Dropdown Content (Hidden by Default) */
  .dropdown-content {
    background-color: white;
    border: 2px solid rgba(0, 83, 123, 0.8);
    border-radius: 16px;
    position: relative;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  }
  
    /* Links inside the dropdown */
    .dropdown-content a {
      color: rgba(0, 83, 123, 0.8);
      text-decoration: none;
      display: block;
    }
  
      /* Change color of dropdown links on hover */
      .dropdown-content a:hover {
        background-color: rgba(0, 83, 123, 0.8);
        color: white;
        cursor: pointer;
        text-decoration: none;
      }
  
      /* Change color of dropdown links on focus */
      .dropdown-content a:focus {
        background-color: rgba(0, 83, 123, 0.8);
        color: white;
        text-decoration: none;
      }
  
  .show-dropdown {
    display: block;
  }
  
  .top-drop {
    border-radius: 13px 13px 0 0;
  }
  
  .middle-drop {
    border-radius: 0 0 0 0;
  }
  
  .bottom-drop {
    border-radius: 0 0 13px 13px;
  }
  
  .drop-button {
    margin: 0;
    padding: 8px 0;
    border: hidden;
    width: 340px;
  }
  
  /* Change the background color of the dropdown button when the dropdown content is shown */
  .dropdown:hover .dropbtn {
    background-color: rgba(0, 83, 123, 0.8);
    color: white;
  }
  
  /* Change the background color of the dropdown button when the dropdown content is shown */
  .dropdown:focus .dropbtn {
    background-color: rgba(0, 83, 123, 0.8);
    color: white;
  }
  
  /* div container for the application sectio */
  .app-body {
    display: table;
    height: calc( 100vh - 535px );
    min-height: 389px;
    width: 1140px;
    margin: 30px 0 110px 0;
  }
  
  .vertical-center {
    display: table-cell;
    vertical-align: middle;
  }
  
  /* Flex container for the five application links */
  .app-container {
    display: flex;
  }
  
  .app-flex-box {
    padding: 20px 8px 20px 8px;
    font-size: 36px;
    flex: 20%;
  }
  
  /* Application Links */
  .app-image {
    border: hidden;
    border-radius: 16px;
  }

  
  .logo {
    margin: 20px auto;
    height: 100px;
  }
  
  @media only screen and (min-width: 768px) {
    .logo {
      margin: 28px;
      float: left;
    }
  }
  
  .title {
    padding: 28px;
    font-size: 22px;
    vertical-align: middle;
    text-align: center;
    font-weight: bold;
    width:100%;
  }
  
  button {
    background: rgba(24, 65, 75, 1);
    border-radius: 8px;
    border: 2px solid rgba(24, 65, 75, 1);
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    padding: 10px 25px 10px 25px;
    font-size: 16px;
    cursor: pointer;
  }
.mat-radio-checked .mat-radio-outer-circle{
  background-color: black;
}
  button:hover {
    background-color: white;
    color: rgba(24, 65, 75, 1);
  }

  
    /*--- Break Line ---*/
  
    .break-line {
      background-color: rgba(0, 86, 123, 0.8);
      height: 2px;
      width: 600px;
      margin: 8px 180px;
    }
  
    .error-message {
      color: red; 
      width: 600px;
      text-decoration: underline;
      font-size: 20px;
    }

html, body { height: 100%; }
body { margin: 0; font-family: Montserrat }

    .selected-button{
      background-color: rgba(54, 99, 117, 0.2);
      border: 2px solid #366375;
      border-radius: 12px;
      color: rgba(24, 65, 75, 1);
      padding: 3px 20px;
      text-align: center;
      display: inline-block;
      font-size: 14px;
      font-family: Montserrat;
      font-weight: 600;
      cursor: pointer;
      margin-top:10px;
      margin-bottom:10px;
    }
  